<template>
<div>
  <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">

    <template slot="header">
      <vs-dropdown vs-trigger-click class="cursor-pointer">
        <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
          <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
        </div>
        <vs-dropdown-menu>
          <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangeLength(item)">
            <span>{{ item }}</span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </template>

    <template slot="thead">
      <vs-th>Action</vs-th>
      <vs-th>File Name</vs-th>
      <vs-th>File</vs-th>
      <vs-th>Imported By</vs-th>
      <vs-th>Start</vs-th>
      <vs-th>End</vs-th>
      <vs-th>Status</vs-th>
      <vs-th>Remark</vs-th>
    </template>

    <template slot-scope="{ data }">
    <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
      <vs-td>
        <div class="vx-input-group flex">
          <vs-button
            size="small"
            color="warning"
            icon-pack="feather"
            icon="icon-eye"
            title="Detail"
            @click.stop="getDetail(tr.id)"
          ></vs-button>
        </div>
      </vs-td>
      <vs-td>{{ tr.file_name }}</vs-td>
      <vs-td>
        <vs-button
          v-if="tr.file_url"
          title="Download Imported File"
          size="small"
          color="success"
          icon-pack="feather"
          icon="icon-download"
          @click.stop="download(tr)"
        />
        <span v-else>Unavailable</span>
      </vs-td>
      <vs-td>{{ tr.created_by }}</vs-td>
      <vs-td>{{ utcToLocal(tr.start_process_at) }}</vs-td>
      <vs-td>{{ utcToLocal(tr.end_process_at) }}</vs-td>
      <vs-td>{{ tr.status }}</vs-td>
      <vs-td>{{ tr.remark }}</vs-td>
    </vs-tr>
      </template>


  </vs-table>
  <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
</div>
</template>
<script>
import moment from "moment";

export default {
  props: {
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "",
        sort: "",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      }
    }
  },
  methods:{
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangeLength(val) {
      this.table.length = val === "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getDetail(id) {
      this.$emit("logImportLine", id);
    },
    download(tr) {
      const link = document.createElement('a');
      link.href = tr.file_url;
      link.download = tr.file_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async getData() {
      try {
        let params = {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          status: this.status,
          type: "work-order"
        }


        this.$vs.loading();
        const response = await this.$http.get("api/wms/v1/work-order/log-import", {params})

        if (response.code !== 200) {
          throw new Error(response.message)
        }

        this.table.total = response.data.total_record;
        this.table.totalPage = response.data.total_page;
        this.table.totalSearch = response.data.total_record_search;
        this.table.length = response.data.total_record_per_page;
        this.table.data = response.data.records;
        this.setStartEnd();
        this.$vs.loading.close();


      } catch (e) {
        console.log(e)
      } finally {
        this.$vs.loading.close();
      }
    },
    setStartEnd() {
      let valStart = this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total === 0) {
        valStart = 0;
      }

      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total && this.table.search !== "") {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    utcToLocal(val) {
      if (val) {
        return moment(val)
          .utc()
          .local()
          .format("DD MMM YYYY HH:mm:ss");
      }
    },

  },
  watch:{
    draw() {
      console.log(this.draw)
      this.getData();
    },
  },
  computed:{
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  }
}
</script>
