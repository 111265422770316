<template>
    <vx-card title="Import Work Order">
        <div class="vx-row mb-6">
            <div class="vx-col w-full flex items-center">
                <vs-button class="mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                    @click="handleBack()">Back</vs-button>
                <vs-button class="mt-2 ml-2" color="primary" type="border" icon-pack="feather" icon="icon-download"
                    @click="handleDownloadTemplate()">Download
                    Template</vs-button>
                <vs-button class="mt-2 ml-2" color="success" type="border" icon-pack="feather" icon="icon-plus"
                    @click="$refs.file.click()">New
                    Import</vs-button>
                <input type="file" ref="file" accept=".xls,.xlsx" @change="handleImport" style="display: none;" :key="fileKey" />

            </div>
        </div>
        <div class="flex md:flex-row">
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base">
                <vs-tabs v-model="activeTab">
                    <vs-tab label="Import">
                      <tableLogImport :draw="draw" @logImportLine="handleLogImportLine" />
                    </vs-tab>
                  <vs-tab label="Import Line">
                    <tableLogImportLine :draw="drawLine" :logImportID="logImportID"/>
                  </vs-tab>
                </vs-tabs>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment-timezone";
import tableLogImport from "./TableLogImport.vue";
import tableLogImportLine from "./TableLogImportLine.vue";

export default {
    components: {
      tableLogImport,
      tableLogImportLine
    },
    data() {
        return {
          fileKey: 0,
          draw: 0,
          drawLine: 0,
          activeTab: 0,
          logImportID: 0,
          now: moment().tz("Asia/Jakarta").format("YYYY-MM-DD_HH-mm-ss"),
        }
    },
    methods: {
        handleBack() {
            this.$router.push({
                name: "work-order-ho"
            })
        },
        handleFilter() {
          this.draw++
          this.drawLine++
        },
      async handleDownloadTemplate() {
            try {
                const fileName = "IMPORT_WORK_ORDER"
                this.$vs.loading()
                const response = await this.$http.get("api/wms/v1/work-order/download-import-template", {
                    responseType: "arraybuffer",
                    headers: {
                        Accept: "application/octet-stream",
                    },
                })

                if (response.status === "error") {
                    throw new Error(response.message);
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))

                const fileLink = document.createElement("a")
                fileLink.href = fileURL

                const fileFormat = `download ${fileName.toUpperCase()} - ${this.now}.xlsx`

                fileLink.setAttribute(
                    "download", fileFormat
                )

                document.body.appendChild(fileLink)
                fileLink.click()
            } catch (error) {
                this.$vs.dialog({
                    type: "alert",
                    color: "danger",
                    title: `An error occured`,
                    text: error,
                    acceptText: "Confirm",
                });
            } finally {
                this.$vs.loading.close()
            }
        },
        handleImport(e) {
            this.$vs.dialog({
                type: "confirm",
                title: `Import from ${e.target.files[0].name}`,
                accept: () => {
                    this.import(e)
                }
            })
        },
        handleLogImportLine(id) {
          this.activeTab = 1;
          this.logImportID = id;
        },
      async import(file) {
            try {
                this.$vs.loading()
                const formData = new FormData()
                formData.append("file", file.target.files[0])

                const response = await this.$http.post("api/wms/v1/work-order/import-work-order", formData)

                if (response.code !== 200) {
                    throw new Error(response.message);
                }

                this.$vs.dialog({
                    type: "alert",
                    color: "success",
                    title: `Success`,
                    text: response.message,
                    acceptText: "Confirm",
                });
                this.fileKey++
                this.handleFilter()
            } catch (error) {
                this.$vs.dialog({
                    type: "alert",
                    color: "danger",
                    title: `Error`,
                    text: error,
                    acceptText: "Confirm",
                });
            } finally {
                this.$vs.loading.close();
            }
        }
    },
}
</script>
