<template>
  <div>
    <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
              :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangeLength(item)">
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>

      <template slot="thead">
        <vs-th>WO Description</vs-th>
        <vs-th>Purpose</vs-th>
        <vs-th>Request Date</vs-th>
        <vs-th>Execution Date</vs-th>
        <vs-th>Notes</vs-th>
        <vs-th>Warehouse Area Code</vs-th>
        <vs-th>Sku Result Code</vs-th>
        <vs-th>Sku Result Unit</vs-th>
        <vs-th>Sku Result Quantity</vs-th>
        <vs-th>Sku Source Code</vs-th>
        <vs-th>Sku Source Unit</vs-th>
        <vs-th>Sku Source Quantity</vs-th>
        <vs-th>Sku Source Batch</vs-th>
        <vs-th>Sku Source ED</vs-th>
        <vs-th>Status</vs-th>
        <vs-th>Remark</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
          <vs-td>{{ tr.data.description }}</vs-td>
          <vs-td>{{ tr.data.purpose }}</vs-td>
          <vs-td>{{ tr.data.request_date }}</vs-td>
          <vs-td>{{ tr.data.execution_date }}</vs-td>
          <vs-td>{{ tr.data.note }}</vs-td>
          <vs-td>{{ tr.data.warehouse_area_code }}</vs-td>
          <vs-td>{{ tr.data.sku_result_code }}</vs-td>
          <vs-td>{{ tr.data.sku_result_unit }}</vs-td>
          <vs-td>{{ tr.data.sku_result_quantity }}</vs-td>
          <vs-td>{{ tr.data.sku_source_code }}</vs-td>
          <vs-td>{{ tr.data.sku_source_unit }}</vs-td>
          <vs-td>{{ tr.data.sku_source_quantity }}</vs-td>
          <vs-td>{{ tr.data.sku_source_batch }}</vs-td>
          <vs-td>{{ tr.data.sku_source_ed }}</vs-td>
          <vs-td>{{ tr.status }}</vs-td>
          <vs-td>{{ tr.remark }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />

  </div>
</template>

<script>

export default {
  props: {
    logImportID: {
      type: Number,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "",
        sort: "",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      }
    }
  },
  mounted() {
    if (this.logImportID) {
      this.getData();
    }
  },
  watch:{
    async draw() {
      if (this.logImportID > 0) {
        await this.getData();
      }
    }
  },
  methods:{
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangeLength(val) {
      this.table.length = val === "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    async getData() {
      try {
        this.$vs.loading();
        let params = {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          log_import_id: this.logImportID,
        }

        const response = await this.$http.get("api/wms/v1/work-order/log-import-line", {params})

        console.log(response.data.records)

        this.table.total = response.data.total_record;
        this.table.totalPage = response.data.total_page;
        this.table.totalSearch = response.data.total_record_search;
        this.table.length = response.data.total_record_per_page;
        this.table.data = response.data.records;
        this.setStartEnd();

      } catch (e) {
        console.log(e);
      } finally {
        this.$vs.loading.close();
      }
    },
    setStartEnd() {
      let valStart = this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total === 0) {
        valStart = 0;
      }

      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total && this.table.search !== "") {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  computed:{
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  }
}
</script>
